import PropTypes from 'prop-types';
import { useContext } from 'react';
import StoreContext from 'context/SiteContext';
import Select from 'components/atoms/Select';

const SampleLineItemQuantitySelector = ({ lineItemId, quantity }) => {
    const options = { 1: 1, 2: 2, 3: 3, 4: 4 };

    const { updateLineItem } = useContext(StoreContext);

    const handleUpdateQuantity = e => {
        if (e?.target?.value && quantity !== e?.target?.value) {
            updateLineItem(lineItemId, e.target.value);
        }
    };

    return (
        <Select
            options={options}
            onChange={handleUpdateQuantity}
            defaultValue={`${quantity}`}
            padding="0"
            align="center"
        />
    );
};

SampleLineItemQuantitySelector.propTypes = {
    lineItemId: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
};

export default SampleLineItemQuantitySelector;
